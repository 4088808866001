.nav {
    height: calc(100vh - 50px);
    background-color: #fff;
    flex-shrink: 0;
    width: 100%;
    flex-basis: 200px;
    visibility: visible; }
.menuIcon {
    width: 18px;
    height: 18px;
    margin-right: 6px; }
.drop-down-menu {
    list-style: none;
    a {
        display: block;
        text-decoration: none;
        margin: 0 12px;
        padding: 8px 4px;
        height: 60px;
        color: #999;
        border-bottom: 1px solid #efefef;
        &:hover {
            color: #1890ff;
            border-bottom: 1px solid #e6f7ff; } }
    &>li {
        position: relative;
        width: 140px;
        height: 60px;
        text-align: center;
        &:hover {
            border-right: 4px inset #1890ff;
            background-color: #e6f7ff;
            ul {
                display: block; } } }
    ul {
        position: absolute;
        display: none;
        width: 160px;
        left: 120px;
        top: 0px;
        background-color: #fff;
        text-align: left;
        z-index: 1;
        box-shadow: 5px 5px 10px #0000001A;
        li {
            position: relative;
            width: 160px;
            height: 45px;
            &:hover {
                background-color: #e6f7ff; } }
        a {
            height: 45px;
            line-height: 45px;
            padding: 0; } } }
.ant-menu-inline .ant-menu-item {
    a {
        color: #939393; }
    &:focus, &:active {
        background-color: #dbeeff; } }
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #1890ff; }
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #dbeeff;
    a {
        color: #144875;
        &:focus {
            outline: none; } } }
.menuGroup {
    display: flex;
    color: #60c4c8;
    align-items: center; }

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background-image: -webkit-gradient(linear, left top, right top, from(#d9d9d9), to(#d9d9d9)); }
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background-image: -webkit-gradient(linear, left top, right top, from(#d9d9d9), to(#d9d9d9)); }
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, #60c4c8, #60c4c8); }

.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    box-shadow: none;
    background: transparent; }

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom; }

.ant-menu-horizontal > .ant-menu-submenu:hover {
    color: #ffffff!important; }

.ant-menu-inline {
    width: auto; }
