.header_title {
    height: 48px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background: #d9d9d9;
    background-size: 120% 100%;
    color: #ffffff; }

.header_logo {
    width: 117.24px;
    height: 31.05px;
    margin: 8px; }

.header_name {
    font-weight: 500;
    font-size: 115%;
    letter-spacing: 2px; }

.header_tab {
    font-weight: 500;
    font-size: 115%;
    letter-spacing: 2px; }

.header_userName {
    font-size: 80%;
    margin-right: 20px; }

.logout_button {
    background: #F29423 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    &:hover, &:active, &:focus {
        background: #F29423 0% 0% no-repeat padding-box;
        color: #fff; } }

.ant-list-split {
    .lang-list-item {
        cursor: pointer;
        justify-content: center;
        color: #939393;
        border-bottom: none; } }
