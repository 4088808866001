.main {
    position: relative;
    background-color: #F3F3F3;
    width: 100%;
    height: 100%;
    flex-basis: calc( 100vw - 200px );
    padding: 24px;
    &.fullscreen {
        flex-basis: 100%;
        padding: 0; }
    overflow-y: scroll; }

.button, .input {
    border-radius: 15px; }

.title {
    color: #707070;
    font-size: 20px;
    margin-bottom: 16px; }

.card {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px #0000001A;
    border-radius: 4px;
    padding: 18px 24px;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px; }
    .title {
        font-size: 16px;
        color: #1890ff; } }
.transfer {
    label.ant-checkbox-wrapper.ant-transfer-list-checkbox {
        display: none; } }
.transfer {
    li.ant-transfer-list-content-item {
        display: contents;
        overflow-y: auto; } }
.box {
    border: 1px solid #ccc;
    border-radius: 0px 5px;
    padding: 10px;
    width: calc(50% - 25px);
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto; }
.chart-title {
    font-weight: 500;
    font-size: 18px; }
.chart-container {
    border: 1px solid #707070;
    padding: 50px; }
.ant-form-item {
    margin-bottom: 0px; }
